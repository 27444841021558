import React, { useEffect, useMemo, useState } from 'react';
import styles from './Accounts.module.scss';
import { useTranslation } from 'react-i18next';
import Select, { components } from 'react-select';
import { colourStylesStatus } from '../../components/SelectStatusStyles';
import { colourStylesCurrency } from '../../components/SelectCurrencyStyles';
import AccountsTable from '../../components/AccountsTable';
import axios from 'axios';
import secureLocalStorage from 'react-secure-storage';
import moment from 'moment';
import { FormControlLabel, styled, Switch } from '@mui/material';
import AddAccountDrawer from '../../components/AddAccountDrawer';
import { Link } from 'react-router-dom';

export default function Accounts({ theme, eurToUsd,accounts,setSnackType,setSnackMessage,handleClick }) {
    const { t, i18n } = useTranslation();
    const [open, setOpen] = useState(false);
    const [search, setSearch] = useState("");
    const [searchType, setSearchType] = useState(false);
    const [searchCurrency, setSearchCurrency] = useState(false);
    const [bufferAccounts, setBufferAccounts] = useState(accounts);

    const toggleDrawer = (newOpen) => () => {
        setOpen(newOpen);
    };

    const CustomOption = (props) => {
        const { innerProps, innerRef } = props;
        return (
            <div ref={innerRef} {...innerProps} className={props.isSelected ? styles.custom_option_selected : styles.custom_option}>
                <div className={styles.option} style={{ color: props.data.color, backgroundColor: props.data.background, width: 'auto' }}>
                    {props.data.label}
                </div>
            </div>)
    }

    const DropdownIndicator = ({ children, ...props }) => (
        <components.DropdownIndicator {...props}>
            <div className={styles.single_value_container}>
                <div className={styles.custom_icon}>
                    <svg width={"1.7vh"} height={"1.6vh"} viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M13.5003 6L8.83366 10L4.16699 6" stroke="#A0A0AB" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                </div>
                {children}
            </div>
        </components.DropdownIndicator>
    );


    const IOSSwitch = styled((props) => (
        <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
    ))(() => ({
        width: 32,
        height: 20,
        padding: 0,
        '& .MuiSwitch-switchBase': {
            padding: 0,
            margin: 2,
            transitionDuration: '300ms',
            '&.Mui-checked': {
                transform: 'translateX(12px)',
                color: '#fff',

                '& .MuiSwitch-thumb': {
                    backgroundColor: '#fcfcfc',
                },
                '& + .MuiSwitch-track': {
                    backgroundColor: '#a855f7',
                    opacity: 1,
                    border: 0,
                },
                '&.Mui-disabled + .MuiSwitch-track': {
                    opacity: 0.5,
                },
            },
            '&.Mui-focusVisible .MuiSwitch-thumb': {
                color: '#fcfcfc',
                border: '6px solid #fff',
            },
            '&.Mui-disabled .MuiSwitch-thumb': {
                color: theme === 'dark' ? '#3F3F46' : '#FCFCFD',

            },
            '&.Mui-disabled + .MuiSwitch-track': {
                opacity: theme === 'dark' ? 0.3 : 0.7,
            },
        },
        '& .MuiSwitch-thumb': {
            boxSizing: 'border-box',
            width: 16,
            height: 16,
            backgroundColor: theme === 'dark' ? '#3F3F46' : '#FCFCFD',
        },
        '& .MuiSwitch-track': {
            borderRadius: 26 / 2,
            backgroundColor: theme === 'dark' ? '#131316' : '#D1D5DB',
            opacity: 1,
            transition: 'all 0.15s ease-in-out',
        },
    }));

    const columns = useMemo(
        () => [
            {
                accessorKey: 'AccountTitle',
                header: t('Name Account'),
                size: 160,
                grow: 1,
                Header: () => (
                    <div className={styles.header_cell}>
                        <span style={{ fontWeight: '500', lineHeight: '1.6vh' }} className={styles.header_name}>{t('Name Account')}</span>
                        <div className={styles.sort_icon}>
                            <svg xmlns="http://www.w3.org/2000/svg" width={"0.8vh"} height={"0.5vh"} viewBox="0 0 8 5" fill="none">
                                <path d="M1 4L4 1L7 4" stroke="#9CA3AF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                            <svg xmlns="http://www.w3.org/2000/svg" width={"0.8vh"} height={"0.5vh"} viewBox="0 0 8 5" fill="none">
                                <path d="M7 1L4 4L1 1" stroke="#9CA3AF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                        </div>
                    </div>
                ),
                Cell: ({ row }) => {
                    return (
                        <Link to={`/cabinet/accounts/${row.original.Id}`} style={{ textDecoration: 'none', color: theme === 'dark' ? '#fcfcfc' : '#1f2937' }}>
                            {row.original.AccountTitle}
                        </Link>
                    )
                },
            },
            {
                accessorKey: 'Number',
                header: t('Number'),
                size: 70,
                grow: 1,
                Header: () => (
                    <div className={styles.header_cell}>
                        <span style={{ fontWeight: '500', lineHeight: '1.6vh' }} className={styles.header_name}>{t('Number')}</span>
                        <div className={styles.sort_icon}>
                            <svg xmlns="http://www.w3.org/2000/svg" width={"0.8vh"} height={"0.5vh"} viewBox="0 0 8 5" fill="none">
                                <path d="M1 4L4 1L7 4" stroke="#9CA3AF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                            <svg xmlns="http://www.w3.org/2000/svg" width={"0.8vh"} height={"0.5vh"} viewBox="0 0 8 5" fill="none">
                                <path d="M7 1L4 4L1 1" stroke="#9CA3AF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                        </div>
                    </div>
                ),
                Cell: ({ row }) => {
                    return (
                        <span >
                            {row.original.Id}
                        </span>
                    )
                },
            },
            {
                accessorKey: 'Currency',
                header: t('Currency'),
                size: 110,
                grow: 1,
                Header: () => (
                    <div className={styles.header_cell}>
                        <span style={{ fontWeight: '500', lineHeight: '1.6vh' }} className={styles.header_name}>{t('Currency')}</span>
                        <div className={styles.sort_icon}>
                            <svg xmlns="http://www.w3.org/2000/svg" width={"0.8vh"} height={"0.5vh"} viewBox="0 0 8 5" fill="none">
                                <path d="M1 4L4 1L7 4" stroke="#9CA3AF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                            <svg xmlns="http://www.w3.org/2000/svg" width={"0.8vh"} height={"0.5vh"} viewBox="0 0 8 5" fill="none">
                                <path d="M7 1L4 4L1 1" stroke="#9CA3AF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                        </div>
                    </div>
                ),
                Cell: ({ row }) => {
                    return (
                        <span style={{ width: 'max-content', display: 'flex', alignItems: 'center', gap: '0.8vh' }}>
                            {row.original.AccountCurrency === 'USD'
                                ?
                                <svg xmlns="http://www.w3.org/2000/svg" width={'2.4vh'} height={'2.4vh'} viewBox="0 0 24 24" fill="none">
                                    <g clipPath="url(#clip0_270_9485)">
                                        <path d="M11.818 23.2806C18.0769 23.2806 23.1122 18.2454 23.1122 11.9865C23.1122 5.72764 18.0769 0.692383 11.818 0.692383C5.55918 0.692383 0.523926 5.72764 0.523926 11.9865C0.523926 18.2454 5.55918 23.2806 11.818 23.2806Z" fill="#00B4A0" />
                                        <path d="M14.9239 13.7738C14.9239 12.1268 13.9357 11.5621 11.9593 11.3268C10.5475 11.1386 10.2652 10.7621 10.2652 10.1032C10.2652 9.44432 10.7358 9.0209 11.6769 9.0209C12.524 9.0209 12.9946 9.30325 13.2298 10.0091C13.2769 10.1503 13.4181 10.2444 13.5593 10.2444H14.3121C14.5004 10.2444 14.6416 10.1032 14.6416 9.91506V9.86796C14.4533 8.83263 13.6063 8.03266 12.524 7.93858V6.80916C12.524 6.62089 12.3828 6.47971 12.1475 6.43262H11.4417C11.2534 6.43262 11.1122 6.57379 11.0651 6.80916V7.89148C9.65337 8.07976 8.75934 9.0209 8.75934 10.1974C8.75934 11.7504 9.70046 12.3621 11.6769 12.5974C12.9946 12.8327 13.4181 13.115 13.4181 13.868C13.4181 14.621 12.7592 15.1386 11.8652 15.1386C10.6416 15.1386 10.2181 14.6209 10.0769 13.915C10.0299 13.7268 9.88874 13.6326 9.74756 13.6326H8.9475C8.75934 13.6326 8.61816 13.7738 8.61816 13.9621V14.0092C8.80632 15.1856 9.55929 16.0327 11.1122 16.268V17.3974C11.1122 17.5856 11.2534 17.7268 11.4886 17.7739H12.1945C12.3828 17.7739 12.524 17.6327 12.5711 17.3974V16.268C13.9828 16.0327 14.9239 15.0444 14.9239 13.7738Z" fill="white" />
                                        <path d="M9.419 18.7157C5.74842 17.3981 3.86603 13.304 5.23081 9.68037C5.93669 7.7039 7.48963 6.19806 9.419 5.49217C9.60728 5.39809 9.70136 5.25692 9.70136 5.02155V4.36276C9.70136 4.17449 9.60728 4.03331 9.419 3.98633C9.37191 3.98633 9.27783 3.98633 9.23073 4.03331C4.76018 5.44508 2.31308 10.1981 3.72485 14.6686C4.57191 17.3039 6.59547 19.3275 9.23073 20.1745C9.419 20.2686 9.60728 20.1745 9.65426 19.9863C9.70136 19.9393 9.70136 19.8922 9.70136 19.7981V19.1392C9.70136 18.998 9.56018 18.8099 9.419 18.7157ZM14.4073 4.03331C14.219 3.93923 14.0307 4.03331 13.9837 4.22158C13.9367 4.26868 13.9367 4.31566 13.9367 4.40986V5.06864C13.9367 5.25692 14.0778 5.44508 14.219 5.53927C17.8896 6.85684 19.772 10.951 18.4072 14.5746C17.7013 16.551 16.1484 18.0569 14.219 18.7628C14.0307 18.8568 13.9367 18.998 13.9367 19.2334V19.8922C13.9367 20.0804 14.0307 20.2216 14.219 20.2686C14.2661 20.2686 14.3602 20.2686 14.4073 20.2216C18.8778 18.8099 21.3249 14.0568 19.9132 9.58629C19.0661 6.90394 16.9954 4.88037 14.4073 4.03331Z" fill="white" />
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_270_9485">
                                            <rect width={24} height={24} fill="white" />
                                        </clipPath>
                                    </defs>
                                </svg>
                                : 
                                <svg viewBox="0 0 32 32" width={'2.4vh'} height={'2.4vh'} xmlns="http://www.w3.org/2000/svg" fill="#000000"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <g fill="none" fill-rule="evenodd"> <circle cx="16" cy="16" fill="#0f8ff8" r="16"></circle> <path d="M8 19.004L8.81 17h.857a16.279 16.279 0 01-.034-1.03c0-.448.019-.864.056-1.25H8l.81-2.003h1.274C11.27 8.906 13.944 7 18.103 7c1.367 0 2.666.177 3.897.532v2.524a8.92 8.92 0 00-3.683-.776c-2.493 0-4.096 1.146-4.81 3.438h7.423l-.81 2.003h-7.097a6.938 6.938 0 00-.056.995c0 .479.015.907.045 1.285h6.183l-.8 2.003H13.44c.533 1.389 1.183 2.355 1.949 2.9.765.544 1.858.816 3.277.816 1.014 0 2.125-.247 3.334-.741v2.373c-1.149.432-2.515.648-4.1.648-4.167 0-6.803-1.999-7.906-5.996z" fill="#ffffff"></path> </g> </g></svg>
          
                            }
                            {row.original.AccountCurrency}
                        </span>
                    )
                },
            },
            {
                accessorKey: 'Balance',
                header: t('Balance'),
                size: 100,
                grow: 1,
                Header: () => (
                    <div className={styles.header_cell}>
                        <span style={{ fontWeight: '500', lineHeight: '1.6vh' }} className={styles.header_name}>{t('Balance')}</span>
                        <div className={styles.sort_icon}>
                            <svg xmlns="http://www.w3.org/2000/svg" width={"0.8vh"} height={"0.5vh"} viewBox="0 0 8 5" fill="none">
                                <path d="M1 4L4 1L7 4" stroke="#9CA3AF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                            <svg xmlns="http://www.w3.org/2000/svg" width={"0.8vh"} height={"0.5vh"} viewBox="0 0 8 5" fill="none">
                                <path d="M7 1L4 4L1 1" stroke="#9CA3AF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                        </div>
                    </div>
                ),
                Cell: ({ row }) => {
                    return (
                        <span>
                            {row.original.AccountCurrency == "EUR" ? "€" : "$"} {parseFloat(row.original.AccountBalance * (row.original.AccountCurrency == "EUR" ? eurToUsd : 1)).toFixed(2)}
                        </span>
                    )
                },
            },
            {
                accessorKey: 'Wallet',
                header: t('Wallet'),
                size: 100,
                grow: 1,
                Header: () => (
                    <div className={styles.header_cell}>
                        <span style={{ fontWeight: '500', lineHeight: '1.6vh' }} className={styles.header_name}>{t('Wallet')}</span>
                        <div className={styles.sort_icon}>
                            <svg xmlns="http://www.w3.org/2000/svg" width={"0.8vh"} height={"0.5vh"} viewBox="0 0 8 5" fill="none">
                                <path d="M1 4L4 1L7 4" stroke="#9CA3AF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                            <svg xmlns="http://www.w3.org/2000/svg" width={"0.8vh"} height={"0.5vh"} viewBox="0 0 8 5" fill="none">
                                <path d="M7 1L4 4L1 1" stroke="#9CA3AF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                        </div>
                    </div>
                ),
                Cell: ({ row }) => {
                    return (
                        <span style={row.original.AccountType === 'Real' ? { color: '#52A5E5', padding: '4px 8px', borderRadius: '9999px', fontSize: '1.2vh', background: 'rgba(82, 165, 229, 0.08)' } : { color: '#E9770E', padding: '4px 8px', borderRadius: '9999px', fontSize: '1.2vh', background: 'rgba(233, 119, 14, 0.08)' }}>
                            {row.original.AccountType == "Fake"?"Demo":"Real"}
                        </span>
                    )
                },
            },
            // {
            //     accessorKey: 'CreationDate',
            //     header: t('Creation Date'),
            //     size: 100,
            //     grow: 1,
            //     Header: () => (
            //         <div className={styles.header_cell}>
            //             <span style={{ fontWeight: '500', lineHeight: '1.6vh' }} className={styles.header_name}>{t('Creation Date')}</span>
            //             <div className={styles.sort_icon}>
            //                 <svg xmlns="http://www.w3.org/2000/svg" width={"0.8vh"} height={"0.5vh"} viewBox="0 0 8 5" fill="none">
            //                     <path d="M1 4L4 1L7 4" stroke="#9CA3AF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            //                 </svg>
            //                 <svg xmlns="http://www.w3.org/2000/svg" width={"0.8vh"} height={"0.5vh"} viewBox="0 0 8 5" fill="none">
            //                     <path d="M7 1L4 4L1 1" stroke="#9CA3AF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            //                 </svg>
            //             </div>
            //         </div>
            //     ),
            //     Cell: ({ row }) => {
            //         return (
            //             <span>
            //                 {moment.utc(row.original.CreationDate).local().format("YYYY-MM-DD HH:mm")}
            //             </span>
            //         )
            //     },
            // },
            // {
            //     accessorKey: 'Active',
            //     header: t('Active / Inactive'),
            //     size: 100,
            //     grow: 1,
            //     Header: () => (
            //         <div className={styles.header_cell} style={{ width: '100%', textAlign: "right" }}>
            //             <span style={{ fontWeight: '500', lineHeight: '1.6vh' }} className={styles.header_name}>{t('Active / Inactive')}</span>
            //             <div className={styles.sort_icon}>
            //                 <svg xmlns="http://www.w3.org/2000/svg" width={"0.8vh"} height={"0.5vh"} viewBox="0 0 8 5" fill="none">
            //                     <path d="M1 4L4 1L7 4" stroke="#9CA3AF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            //                 </svg>
            //                 <svg xmlns="http://www.w3.org/2000/svg" width={"0.8vh"} height={"0.5vh"} viewBox="0 0 8 5" fill="none">
            //                     <path d="M7 1L4 4L1 1" stroke="#9CA3AF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            //                 </svg>
            //             </div>
            //         </div>
            //     ),
            //     Cell: ({ row }) => {
            //         return (
            //             <span style={{ width: '100%', justifyContent: 'right' }}>
            //                 <FormControlLabel
            //                     sx={{ margin: 0, '& .MuiSwitch-input': { width: '100%' }, '& .MuiSwitch-root': { margin: 0 } }}
            //                     control={<IOSSwitch sx={{ m: 1 }} defaultChecked />}
            //                 />
            //             </span>
            //         )
            //     },
            // },
        ],
        [i18n.language, theme,accounts],
    )

    const filterAccounts = () => {
        if (search?.length || searchType || searchCurrency) {
          return accounts.filter((val) => {
            const matchesSearch = search?.length
              ? val?.AccountTitle.toString().trim().toLowerCase().includes(search.trim().toLowerCase())
              : true;
      
            const matchesType = searchType
              ? val?.AccountType === (searchType?.value === 1 ? "Real" : "Fake")
              : true;
      
            const matchesCurrency = searchCurrency
              ? val?.AccountCurrency === searchCurrency?.label
              : true;
      
            return matchesSearch && matchesType && matchesCurrency;
          });
        } else {
          return accounts;
        }
    };

    useEffect(()=>{
        setBufferAccounts(filterAccounts())
    },[search,searchType,searchCurrency,accounts])

    return (
        <div className={styles.account}>
            <div className={styles.account_title}>
                <div className={styles.left_side}>
                    <svg xmlns="http://www.w3.org/2000/svg" width={'1.6vh'} height={'1.6vh'} viewBox="0 0 16 16" fill="none">
                        <ellipse cx="7.99992" cy="3.99967" rx="2.66667" ry="2.66667" />
                        <ellipse cx="7.99992" cy="11.3337" rx="4.66667" ry="2.66667" />
                    </svg>
                    {t('Accounts')}
                </div>
                <div className={styles.right_side} onClick={toggleDrawer(true)}>
                    {t('Open new account')}
                    <svg xmlns="http://www.w3.org/2000/svg" width={'1.6vh'} height={'1.6vh'} viewBox="0 0 16 16" fill="none">
                        <g clipPath="url(#clip0_339_4851)">
                            <circle cx="8.00016" cy="7.99967" r="6.66667" stroke="#FCFCFC" strokeWidth="1.5" />
                            <path d="M10 8.00002L8 8.00002M8 8.00002L6 8.00002M8 8.00002L8 6M8 8.00002L8 10" stroke="#FCFCFC" strokeWidth="1.5" strokeLinecap="round" />
                        </g>
                        <defs>
                            <clipPath id="clip0_339_4851">
                                <rect width={16} height={16} fill="white" />
                            </clipPath>
                        </defs>
                    </svg>
                </div>
            </div>
            <div className={styles.account_body}>
                <div className={styles.table_header}>
                    <div className={styles.left_side}>
                        <svg xmlns="http://www.w3.org/2000/svg" width={'1.6vh'} height={'1.6vh'} viewBox="0 0 16 16" fill="none">
                            <g clipPath="url(#clip0_339_5008)">
                                <circle cx="7.66659" cy="7.66634" r="6.33333" stroke="#51525C" />
                                <path d="M13.3333 13.333L14.6666 14.6663" stroke="#51525C" strokeLinecap="round" />
                            </g>
                            <defs>
                                <clipPath id="clip0_339_5008">
                                    <rect width={16} height={16} fill="white" />
                                </clipPath>
                            </defs>
                        </svg>
                        <input placeholder={t('Search')} value={search} onChange={(e)=>setSearch(e.target.value)} />
                    </div>
                    <div className={styles.right_side}>
                        <div className={styles.select}>
                            <Select
                                classNamePrefix="custom_select"
                                className={`${styles.select_select} custom-select-container`}
                                placeholder={'Type'}
                                menuPlacement='auto'
                                options={[{ value: 1, label: 'Real', color: '#52A5E5', background: 'rgba(82, 165, 229, 0.08)' }, { value: 2, label: 'Demo', color: '#E9770E', background: 'rgba(233, 119, 14, 0.08)' }]}
                                styles={colourStylesStatus(theme, 'Sale')}
                                components={{ DropdownIndicator, IndicatorSeparator: () => null, Option: CustomOption }}
                                onChange={(selectedOption) => setSearchType(selectedOption)}
                                value={searchType}
                                isClearable
                                />
                        </div>
                        <div className={styles.select}>
                            <Select
                                classNamePrefix="custom_select"
                                className={`${styles.select_select} custom-select-container`}
                                placeholder={'Type'}
                                menuPlacement='auto'
                                options={[{ value: 1, label: 'USD' }, { value: 2, label: 'EUR' }]}
                                styles={colourStylesCurrency(theme)}
                                components={{ DropdownIndicator, IndicatorSeparator: () => null, Option: CustomOption }}
                                onChange={(selectedOption) => setSearchCurrency(selectedOption)}
                                value={searchCurrency}
                                isClearable
                            />
                        </div>
                    </div>
                    <div className={styles.mobile_add} onClick={toggleDrawer(true)}>
                        <svg xmlns="http://www.w3.org/2000/svg" width={'1.6vh'} height={'1.6vh'} viewBox="0 0 16 16" fill="none">
                            <g clipPath="url(#clip0_339_4851)">
                                <circle cx="8.00016" cy="7.99967" r="6.66667" stroke="#FCFCFC" strokeWidth="1.5" />
                                <path d="M10 8.00002L8 8.00002M8 8.00002L6 8.00002M8 8.00002L8 6M8 8.00002L8 10" stroke="#FCFCFC" strokeWidth="1.5" strokeLinecap="round" />
                            </g>
                            <defs>
                                <clipPath id="clip0_339_4851">
                                    <rect width={16} height={16} fill="white" />
                                </clipPath>
                            </defs>
                        </svg>
                    </div>
                </div>
                <div className={styles.table}>
                    <AccountsTable
                        data={bufferAccounts}
                        columns={columns}
                        theme={theme}
                    />
                </div>
                <div className={styles.mobile_transactions}>
                    <div className={styles.transactions_list}>
                        {bufferAccounts?.map(el =>
                            <div className={styles.transaction} key={el.Id}>
                                <div className={styles.type_status}>
                                    <div className={`${styles.type} ${el?.AccountType === 'Fake' ? styles.with : ""}`}>
                                        {el?.AccountType === 'Fake'?"Demo":"Real"}
                                    </div>
                                    {/* <FormControlLabel
                                        sx={{ margin: 0, '& .MuiSwitch-input': { width: '100%' }, '& .MuiSwitch-root': { margin: 0 } }}
                                        control={<IOSSwitch sx={{ m: 1 }} defaultChecked />}
                                    /> */}
                                </div>
                                <div className={styles.account_amount}>
                                    <div className={styles.account}>
                                        <label htmlFor="">{t('Account')}</label>
                                        <Link to={`/cabinet/accounts/${el?.Id}`}>{el?.AccountTitle}</Link>
                                    </div>
                                    <div className={styles.amount}>
                                        <label htmlFor="">{t('Balance')}</label>
                                        <p className={`${el.Type === 'Withdrawal' ? styles.with : ''}`}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                                <g clip-path="url(#clip0_575_44989)">
                                                    <path d="M7.87878 15.5202C12.0514 15.5202 15.4082 12.1634 15.4082 7.99084C15.4082 3.81826 12.0514 0.461426 7.87878 0.461426C3.7062 0.461426 0.349365 3.81826 0.349365 7.99084C0.349365 12.1634 3.7062 15.5202 7.87878 15.5202Z" fill="#00B4A0" />
                                                    <path d="M9.9492 9.18271C9.9492 8.08469 9.29039 7.70822 7.97276 7.55138C7.03159 7.42587 6.84336 7.17491 6.84336 6.73564C6.84336 6.29638 7.15711 6.0141 7.78452 6.0141C8.34922 6.0141 8.66297 6.20233 8.8198 6.67292C8.8512 6.76704 8.94532 6.82976 9.03943 6.82976H9.54134C9.66685 6.82976 9.76097 6.73564 9.76097 6.6102V6.5788C9.63545 5.88858 9.07076 5.35527 8.34922 5.29255V4.53961C8.34922 4.41409 8.25511 4.31997 8.09827 4.28857H7.62769C7.50217 4.28857 7.40806 4.38269 7.37666 4.53961V5.26115C6.4355 5.38667 5.83948 6.0141 5.83948 6.79844C5.83948 7.83374 6.4669 8.24153 7.78452 8.39844C8.66297 8.55528 8.94532 8.74352 8.94532 9.24551C8.94532 9.74749 8.50606 10.0926 7.91004 10.0926C7.09431 10.0926 6.81196 9.74742 6.71785 9.27683C6.68653 9.15139 6.59241 9.08859 6.49829 9.08859H5.96492C5.83948 9.08859 5.74536 9.18271 5.74536 9.30823V9.33962C5.8708 10.1239 6.37278 10.6886 7.40806 10.8455V11.5985C7.40806 11.7239 7.50218 11.818 7.65901 11.8494H8.12959C8.25511 11.8494 8.34922 11.7553 8.38062 11.5985V10.8455C9.32178 10.6886 9.9492 10.0298 9.9492 9.18271Z" fill="white" />
                                                    <path d="M6.27942 12.4773C3.83236 11.5989 2.57743 8.86948 3.48729 6.45374C3.95787 5.1361 4.99317 4.1322 6.27942 3.66161C6.40493 3.59889 6.46765 3.50477 6.46765 3.34786V2.90867C6.46765 2.78315 6.40493 2.68904 6.27942 2.65771C6.24802 2.65771 6.1853 2.65771 6.1539 2.68904C3.17354 3.63021 1.54214 6.79889 2.48331 9.77926C3.04802 11.5361 4.39706 12.8851 6.1539 13.4498C6.27942 13.5126 6.40493 13.4498 6.43626 13.3243C6.46765 13.293 6.46765 13.2616 6.46765 13.1989V12.7596C6.46765 12.6655 6.37354 12.5401 6.27942 12.4773ZM9.60493 2.68904C9.47942 2.62632 9.3539 2.68904 9.32258 2.81455C9.29118 2.84595 9.29118 2.87727 9.29118 2.94007V3.37926C9.29118 3.50477 9.3853 3.63021 9.47942 3.69301C11.9265 4.57139 13.1814 7.3008 12.2715 9.71654C11.801 11.0342 10.7657 12.0381 9.47942 12.5087C9.3539 12.5714 9.29118 12.6655 9.29118 12.8224V13.2616C9.29118 13.3871 9.3539 13.4812 9.47942 13.5126C9.51082 13.5126 9.57354 13.5126 9.60493 13.4812C12.5853 12.5401 14.2167 9.37139 13.2755 6.39102C12.7108 4.60279 11.3304 3.25374 9.60493 2.68904Z" fill="white" />
                                                </g>
                                                <defs>
                                                    <clipPath id="clip0_575_44989">
                                                        <rect width="16" height="16" fill="white" />
                                                    </clipPath>
                                                </defs>
                                            </svg>
                                            {
                                                el?.AccountCurrency == "EUR"
                                                    ?
                                                    "€"
                                                    :
                                                    "$"
                                            }
                                            {parseFloat(el?.AccountBalance* (el?.AccountCurrency == "EUR" ? eurToUsd : 1)).toFixed(1)}
                                        </p>
                                    </div>
                                </div>
                                <div className={styles.id_date}>
                                    <div className={styles.id}>
                                        ID {el?.Id}
                                    </div>
                                    {/* <div className={styles.date}>
                                        {moment(el?.CreationDate).format("YYYY-MM-DD HH:mm")}
                                    </div> */}
                                </div>
                            </div>)
                        }
                    </div>
                </div>
            </div>
            <AddAccountDrawer open={open} toggleDrawer={toggleDrawer} theme={theme} />
        </div>
    )
}
