import { useState, useRef, useEffect } from 'react';
import styles from '../styles/CarouselMobile.module.scss';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper/modules';
import 'swiper/scss';

export default function CarouselMobile({slides}) {
    const [activeIndex, setActiveIndex] = useState(0);
    const swiperRef = useRef(null);
    const containerRef = useRef(null);


    const handleSlideChange = (swiper) => {
        setActiveIndex(swiper.realIndex % slides.length); 
    };

    const goToSlide = (index) => {
        if (swiperRef.current && swiperRef.current.swiper) {
            swiperRef.current.swiper.slideToLoop(index);
            setActiveIndex(index);
        }
    };

        const [slidesPerView, setSlidesPerView] = useState(1.13);
      
        useEffect(() => {
            const updateSlidesPerView = () => {
              const width = window.innerWidth;
              
              if (width >= 1200) {
                setSlidesPerView(2.5);
              } else if (width >= 1024) {
                setSlidesPerView(2.3);
              } else if (width >= 768) {
                setSlidesPerView(1.75);
              } else if (width >= 700) {
                setSlidesPerView(1.6);
              } else if (width >= 640) {
                setSlidesPerView(1.55);
              } else if (width >= 600) {
                setSlidesPerView(1.5);
              } else if (width >= 550) {
                setSlidesPerView(1.4);
              } else if (width >= 515) {
                setSlidesPerView(1.3);
              } else if (width >= 480) {
                setSlidesPerView(1.25);
              } else if (width >= 450) {
                setSlidesPerView(1.2);
              } else if (width >= 430) {
                setSlidesPerView(1.15);
              } else if (width >= 420) {
                setSlidesPerView(1.12);
              } else if (width >= 400) {
                setSlidesPerView(1.1);
              } else if (width >= 378) {
                setSlidesPerView(1.05);
              } else {
                setSlidesPerView(1);
              }
            };
      
          updateSlidesPerView();

          window.addEventListener('resize', updateSlidesPerView);

    return () => {
      window.removeEventListener('resize', updateSlidesPerView);
    };
  }, []);

    const handleScroll = (e) => {
        e.preventDefault();
        const delta = e.deltaY;

        if (swiperRef.current && swiperRef.current.swiper) {
            if (delta > 0) {
                swiperRef.current.swiper.slideNext();
            } else {
                swiperRef.current.swiper.slidePrev();
            }
        }
    };

    useEffect(() => {
        const container = containerRef.current;
        if (container) {
            container.addEventListener('wheel', handleScroll);
        }

        return () => {
            if (container) {
                container.removeEventListener('wheel', handleScroll);
            }
        };
    }, []);
    return (
        <div className={styles.carouselWrapper} ref={containerRef}>
            <Swiper
                ref={swiperRef}
                className={styles.body}
                spaceBetween={9}
                slidesPerView={slidesPerView}
                centeredSlides={true}
                initialSlide={1}
                direction={'horizontal'}
                loop={true}
                onSlideChange={handleSlideChange}
                pagination={{ clickable: true }}
                modules={[Pagination]} 
            >
                {slides.map((el) => (
                    <SwiperSlide key={el.id} className={styles.slide}>
                        <div className={styles.slide_content}>
                            <div className={styles.top}>
                                <svg xmlns="http://www.w3.org/2000/svg" width={25} height={22} viewBox="0 0 25 22" fill="none">
                                    <path d="M19.8214 0H25L19.75 11.1273H25V22H14.3214V11.1273L19.8214 0ZM5.46429 0H10.6786L5.39286 11.1273H10.6786V22H0V11.1273L5.46429 0Z" fill="#26272B" />
                                </svg>
                                <p className={styles.text}>{el?.text}</p>
                            </div>
                            <h5 className={styles.name}>{el?.name}</h5>
                        </div>
                    </SwiperSlide>
                ))}
            </Swiper>

            <div className={styles.pagination}>
                {slides.map((_, index) => (
                    <span 
                        key={index} 
                        className={`${styles.paginationBullet} ${index === activeIndex ? styles.active : ''}`} 
                        onClick={() => goToSlide(index)}
                    />
                ))}
            </div>
        </div>
    );
};
